
import { useEffect, useState } from 'react';
import './../../App.css';
import inputData from '../../modules/inputData/inputData';
import Preview02 from '../Preview02';

export default function Request_1_b(props){
    // Change Object
    let [dataObj, setDataObj] = useState({});

    // Choose subjects
    const [checkItems, setCheckItems] = useState([]);
    const selectChecked = (checked, value) => {
        if (checked) {
          setCheckItems(item => [...item, value]); 
        } else {
          setCheckItems(checkItems.filter((el) => el !== value));
        }
    };

    return(
        <>
            <div className='form-header'>
                <ul>
                    <li style={{backgroundColor:'#f9e0d0'}}>도면설계 / 견적용 물량도 - 비계</li>
                </ul>
                <ul>
                    <li>구분</li>
                    <li>내용</li>
                </ul>
                <ul>
                    <li>공종</li>
                    <li>
                        {['시스템 비계', '강관 비계(파이프 비계)'].map((a,i)=>{
                            return(
                                <label className='radio-wrap'>
                                    <input
                                    type="radio"
                                    name="r1b_공종"
                                    value={a}
                                    required
                                    /> <span>{a}</span>
                                </label>
                        )})}
                        {/* <label>
                            <select name="r1b_공종">
                                {['-','시스템 비계', '강관 비계(파이프 비계)'].map((a,i)=>{
                                    return(<option value={a}>{a}</option>)
                                })}
                            </select>
                        </label> */}
                    </li>
                </ul>
                <ul>
                    <li>의뢰 종류</li>
                    <li>
                        {['도면 설계', '견적용 물량도'].map((a,i)=>{
                            return(
                                <label className='radio-wrap'>
                                    <input
                                    type="radio"
                                    name="r1b_의뢰종류"
                                    value={a}
                                    required
                                    /> <span>{a}</span>
                                </label>
                        )})}
                        {/* <label>
                            <select name="r1b_의뢰종류">
                                {['-','도면 설계', '견적용 물량도'].map((a,i)=>{
                                    return(<option value={a}>{a}</option>)
                                })}
                            </select>
                        </label> */}
                    </li>
                </ul>
                <ul>
                    <li>납기일</li>
                    <li>
                        <label>
                            <input
                                type="date"
                                name="r1b_납기일"
                                required
                            />
                        </label>
                    </li>
                </ul>
                {/* <ul>
                    <li>의뢰서 자동 작성</li>
                    <li>
                        <label>
                            <input
                            type="checkbox" name="r1b_자동작성" value="체크됨" style={{width:'auto'}}
                            onChange={(e) => selectChecked(e.target.checked, 'r1b_자동작성')}
                            /> 기본 내용으로 자동 작성후 의뢰<br/>(내용 보기를 눌러서 확인 후 해당 내용이 아니면 직접 작성하셔야 합니다.)
                        </label>
                        <br/><Preview02 />
                    </li>
                </ul> */}
                {checkItems.includes('r1b_자동작성')
                ? ''
                : <div className='form-header'>
                    <ul>
                        <li>발판 유형</li>
                        <li>
                            {['1장 타입 (폭 500 1장)', '2장 타입 (폭 400 2장)', '수평 비계', '(직접 작성)'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1b_발판유형"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1b_발판유형', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {dataObj.r1b_발판유형 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1b_발판유형d"
                                    placeholder="직접 작성"
                                    style={{display: dataObj.r1b_발판유형 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                            : ''}
                            {/* <label>
                                <select
                                    name="r1b_발판유형"
                                    onChange={(e)=>{inputData(setDataObj, 'r1b_발판유형', e.target.value)}}
                                >
                                    {['-','1장 타입 (폭 500 1장)', '2장 타입 (폭 400 2장)', '수평 비계', '(직접 작성)'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                                {dataObj.r1b_발판유형 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1b_발판유형d"
                                    placeholder="직접작성"
                                    style={{display: dataObj.r1b_발판유형 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                                : ''}
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>비계 사용용도</li>
                        <li>
                            {['골조 작업용', '골조+마감 작업용', '돌 마감 작업용', '경량 마감 작업용' , '(직접 작성)'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1b_비계사용용도"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1b_비계사용용도', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {dataObj.r1b_비계사용용도 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1b_비계사용용도d"
                                    placeholder="직접 작성"
                                    style={{display: dataObj.r1b_비계사용용도 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                            : ''}
                            {/* <label>
                                <select
                                    name="r1b_비계사용용도"
                                    onChange={(e)=>{inputData(setDataObj, 'r1b_비계사용용도', e.target.value)}}
                                >
                                    {['-', '골조 작업용', '골조+마감 작업용', '돌 마감 작업용', '경량 마감 작업용' , '(직접 작성)'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                                {dataObj.r1b_비계사용용도 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1b_비계사용용도d"
                                    placeholder="직접작성"
                                    style={{display: dataObj.r1b_비계사용용도 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                                : ''}
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>수직 이동통로 유형</li>
                        <li>
                            {['워킹타워 1개소+개구부발판', '개구부발판 30m 이내 1개소', '(직접 작성)'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1b_수직이동통로유형"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1b_수직이동통로유형', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {dataObj.r1b_수직이동통로유형 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1b_수직이동통로유형d"
                                    placeholder="직접 작성"
                                    style={{display: dataObj.r1b_수직이동통로유형 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                            : ''}
                            {/* <label>
                                <select
                                    name="r1b_수직이동통로유형"
                                    onChange={(e)=>{inputData(setDataObj, 'r1b_수직이동통로유형', e.target.value)}}
                                >
                                    {['-','워킹타워 1개소+개구부발판', '개구부발판 30m 이내 1개소', '(직접 작성)'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                                {dataObj.r1b_수직이동통로유형 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1b_수직이동통로유형d"
                                    placeholder="(직접 작성)"
                                    style={{display: dataObj.r1b_수직이동통로유형 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                                : ''}
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>방호선반</li>
                        <li>
                            {['설계', '설계안함'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1b_방호선반"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1b_방호선반', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {/* <label>
                                <select name="r1b_방호선반">
                                    {['-','설계', '설계안함'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>비계 시작 위치</li>
                        <li>
                            {['GL (지상층 지하층 별도 설계)', 'GL (지상층만 설계)', '기초 하단', '기초 상단', '버림 상단', '(직접 작성)'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1b_비계시작위치"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1b_비계시작위치', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {dataObj.r1b_비계시작위치 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1b_비계시작위치d"
                                    placeholder="직접 작성"
                                    style={{display: dataObj.r1b_비계시작위치 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                            : ''}
                            {/* <label>
                                <select
                                    name="r1b_비계시작위치"
                                    onChange={(e)=>{inputData(setDataObj, 'r1b_비계시작위치', e.target.value)}}
                                >
                                    {['-', 'GL (지상층 지하층 별도 설계)', 'GL (지상층만 설계)', '기초 하단', '기초 상단', '버림 상단', '(직접 작성)'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                                {dataObj.r1b_비계시작위치 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1b_비계시작위치d"
                                    placeholder="(직접 작성)"
                                    style={{display: dataObj.r1b_비계시작위치 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                                : ''}
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>비계 설계 구간</li>
                        <li>
                            {['외부 전체', '내부 오픈구' , '외부 전체+내부 오픈구', '외부+내부 전체', '기준층 밑 설계(갱폼 적용)', '(직접 작성)'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1b_비계설계구간"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1b_비계설계구간', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {dataObj.r1b_비계설계구간 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1b_비계설계구간d"
                                    placeholder="직접 작성"
                                    style={{display: dataObj.r1b_비계설계구간 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                            : ''}
                            {/* <label>
                                <select
                                    name="r1b_비계설계구간"
                                    onChange={(e)=>{inputData(setDataObj, 'r1b_비계설계구간', e.target.value)}}
                                >
                                    {['-', '외부 전체', '내부 오픈구' , '외부 전체+내부 오픈구', '외부+내부 전체', '기준층 밑 설계(갱폼 적용)', '(직접 작성)'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                                {dataObj.r1b_비계설계구간 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1b_비계설계구간d"
                                    placeholder="(직접 작성)"
                                    style={{display: dataObj.r1b_비계설계구간 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                                : ''}
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>최하단 발판 설치 여부</li>
                        <li>
                            {['설계', '설계 안함'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1b_최하단발판설치여부"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1b_최하단발판설치여부', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {/* <label>
                                <select name="r1b_최하단발판설치여부">
                                    {['-','설계', '설계 안함'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>자재타입</li>
                        <li>
                            {['OK타입 (H18=1817mm) 설계', '도끼 타입 (H18=1829mm) 설계', '(직접 작성)'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1b_자재타입"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1b_자재타입', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {dataObj.r1b_자재타입 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1b_자재타입d"
                                    placeholder="직접 작성"
                                    style={{display: dataObj.r1b_자재타입 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                            : ''}
                            {/* <label>
                                <select
                                    name="r1b_자재타입"
                                    onChange={(e)=>{inputData(setDataObj, 'r1b_자재타입', e.target.value)}}
                                >
                                    {['-', 'OK타입 (H18=1817mm) 설계', '도끼 타입 (H18=1829mm) 설계', '(직접 작성)'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                                {dataObj.r1b_자재타입 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1b_자재타입d"
                                    placeholder="(직접 작성)"
                                    style={{display: dataObj.r1b_자재타입 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                                : ''}
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>물량산출기준<br/>(클릭 시 내용확인 가능)</li>
                        <li>
                            {['기본 적용', '(직접 작성)'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1b_물량산출기준"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1b_물량산출기준', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {dataObj.r1b_물량산출기준 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1b_물량산출기준d"
                                    placeholder="직접 작성"
                                    style={{display: dataObj.r1b_물량산출기준 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                            : ''}
                            {/* <label>
                                <select
                                    name="r1b_물량산출기준"
                                    onChange={(e)=>{inputData(setDataObj, 'r1b_물량산출기준', e.target.value)}}
                                >
                                    {['-','기본 적용', '(직접 작성)'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                                {dataObj.r1b_물량산출기준 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1b_물량산출기준d"
                                    placeholder="(직접 작성)"
                                    style={{display: dataObj.r1b_물량산출기준 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                                : ''}
                            </label> */}
                            <div style={{display: dataObj.r1b_물량산출기준 == '기본 적용' ? 'block' : 'none'}}>
                                [외부 비계]<br/>
                                길이 : 골조에서 1000mm 이격<br/>
                                높이 : 최상단 발판 높이 +1200mm<br/>
                                면적(㎡)=길이*높이<br/>
                                [내부 비계]<br/>
                                길이 : 골조에서 400mm 이격<br/>
                                높이 : 최상단 발판 높이 +1200mm<br/>
                                면적(㎡)=길이*높이<br/>
                                [수평 비계]<br/>
                                면적 : 평면상 벽체 내측면 면적<br/>
                                높이 : 최상단 발판 높이 +1200mm<br/>
                                체적(㎥)=면적*높이
                            </div>
                        </li>
                    </ul>
                    <ul>
                        <li>구조 검토</li>
                        <li>
                            {['비날인 구조계산서 (구조기술사 도장 X)', '날인 구조계산서 (구조기술사 도장 O)'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1b_구조검토"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1b_구조검토', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {/* <label>
                                <select name="r1b_구조검토">
                                    {['-','비날인 구조계산서 (구조기술사 도장 X)', '날인 구조계산서 (구조기술사 도장 O)'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                            </label> */}
                        </li>
                    </ul>
                </div>}
                <ul>
                    <li>특이사항</li>
                    <li>
                        <label>
                            <input
                                type="text"
                                name="r1b_특이사항"
                                // placeholder="공란을 채워주세요"
                                // required
                            />
                        </label>
                    </li>
                </ul>
            </div>
        </>
    )
}