
import { useEffect, useState } from 'react';
import './../../App.css';
import inputData from '../../modules/inputData/inputData';

export default function Request_7(props){
    const checkItems = props.checkItems

    // Change Object
    let [dataObj, setDataObj] = useState({});

    return(
        <>
            <div className='form-header' style={{display: checkItems.includes('request_7') ? '' : 'none'}}>
                <ul>
                    <li style={{backgroundColor:'#f9e0d0'}}>자동화 설계 프로그램 구매 문의, 오류 신고</li>
                </ul>
                <ul>
                    <li>구분</li>
                    <li>내용</li>
                </ul>
                <ul>
                    <li>문의 및 오류 신고 내용</li>
                    <li>
                        <label>
                            <input
                                type="text"
                                name="r7_문의내용"
                                placeholder="공란을 채워주세요"
                                required
                            />
                        </label>
                    </li>
                </ul>
            </div>
        </>
    )
}