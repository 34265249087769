
import { useEffect, useState } from 'react';
import './../../App.css';
import inputData from '../../modules/inputData/inputData';

export default function Request_3(props){
    const checkItems = props.checkItems

    // Change Object
    let [dataObj, setDataObj] = useState({});

    return(
        <>
            <div className='form-header'>
                <ul>
                    <li style={{backgroundColor:'#f9e0d0'}}>구조검토 의뢰서</li>
                </ul>
                <ul>
                    <li>구분</li>
                    <li>내용</li>
                </ul>
                <ul>
                    <li>공종</li>
                    <li>
                        {['시스템 동바리', '시스템 비계', '강관 동바리', '강관 비계'].map((a,i)=>{
                            return(
                                <label className='radio-wrap'>
                                    <input
                                    type="radio"
                                    name="r3_공종"
                                    value={a}
                                    required
                                    /> <span>{a}</span>
                                </label>
                        )})}
                        {/* <label>
                            <select name="r3_공종">
                                {['-','시스템 동바리', '시스템 비계', '강관 동바리', '강관 비계'].map((a,i)=>{
                                    return(<option value={a}>{a}</option>)
                                })}
                            </select>
                        </label> */}
                    </li>
                </ul>
                <ul>
                    <li>의뢰 종류</li>
                    <li>
                        {['비날인 구조계산서 (구조기술사 도장 X)', '날인 구조계산서 (구조기술사 도장 O)'].map((a,i)=>{
                            return(
                                <label className='radio-wrap'>
                                    <input
                                    type="radio"
                                    name="r3_의뢰종류"
                                    value={a}
                                    required
                                    /> <span>{a}</span>
                                </label>
                        )})}
                        {/* <label>
                            <select name="r3_의뢰종류">
                                {['-', '비날인 구조계산서 (구조기술사 도장 X)', '날인 구조계산서 (구조기술사 도장 O)'].map((a,i)=>{
                                    return(<option value={a}>{a}</option>)
                                })}
                            </select>
                        </label> */}
                    </li>
                </ul>
                <ul>
                    <li>납기일</li>
                    <li>
                        <label>
                            <input
                                type="date"
                                name="r3_납기일"
                                required
                            />
                        </label>
                    </li>
                </ul>
                <ul>
                    <li>검토형태</li>
                    <li>
                        {['2D 검토', '3D 검토', '구조기술사 의견서', 'PDF 날인'].map((a,i)=>{
                            return(
                                <label className='radio-wrap'>
                                    <input
                                    type="radio"
                                    name="r3_검토형태"
                                    value={a}
                                    required
                                    onChange={(e)=>{inputData(setDataObj, 'r3_검토형태', e.target.value)}}
                                    /> <span>{a}</span>
                                </label>
                        )})}
                        {/* <label>
                            <select name="r3_검토형태">
                                {['-', '2D 검토', '3D 검토', '구조기술사 의견서', 'PDF 날인'].map((a,i)=>{
                                    return(<option value={a}>{a}</option>)
                                })}
                            </select>
                        </label> */}
                    </li>
                </ul>
                <ul>
                    <li>특이사항</li>
                    <li>
                        <label>
                            <input
                                type="text"
                                name="r3_특이사항"
                                // placeholder="공란을 채워주세요"
                                // required
                            />
                        </label>
                    </li>
                </ul>
            </div>
        </>
    )
}