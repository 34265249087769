import { useEffect } from 'react';
import './../App.css';
import Law from './Law';

export default function Footer(){

    return(
        <>
            <div className='footer'>
                <div className='footer__con'>
                    <div className='footer__logo'>
                        <img src='/images/logo.png' decoding="async"/>
                    </div>
                    <div className='footer__txt-con'>
                        <div className='footer__txt01'>
                            상호 : JH시스템 | 사업장소재지 : 경기 수원시 팔달구 갓매산로 51 (에스알프라자23차) 6층 601 코스팟 (23호JH시스템) | 대표자명 : 최재호
                        <br/>
                            사업자등록번호 192-35-01294 | 개인정보 보호 책임자 : 최재호
                        <br/>
                            전화번호 : 010-5762-1829 | E-mail : jhsystem@jhsystem.by-works.com
                        <br/>
                        </div>
                        <div className='footer__txt02'>
                            <span>©</span> JH시스템. All Rights Reserved.
                        </div>
                    </div>
                    <div className='footer__law-con'>
                        {/* <a href=''>이용약관</a> */}
                        {/* &nbsp;<span>|</span>&nbsp; */}
                        <a>
                            개인정보처리방침
                            <Law />
                        </a>
                        &nbsp;<span>|</span>&nbsp;
                        <a href='/license'>오픈소스 라이선스</a>
                    </div>
                    <div style={{height:'60px'}}></div>
                </div>
            </div>
        </>
    )
}