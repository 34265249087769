
import { useEffect, useState } from 'react';
import './../../App.css';
import inputData from '../../modules/inputData/inputData';

export default function Request_6(props){
    const checkItems = props.checkItems

    // Change Object
    let [dataObj, setDataObj] = useState({});

    return(
        <>
            <div className='form-header'>
                <ul>
                    <li style={{backgroundColor:'#f9e0d0'}}>기성 도면 의뢰서</li>
                </ul>
                <ul>
                    <li>구분</li>
                    <li>내용</li>
                </ul>
                <ul>
                    <li>공종</li>
                    <li>
                        {['시스템 동바리', '시스템 비계', '강관 동바리', '강관 비계'].map((a,i)=>{
                            return(
                                <label className='radio-wrap'>
                                    <input
                                    type="radio"
                                    name="r6_공종"
                                    value={a}
                                    required
                                    /> <span>{a}</span>
                                </label>
                        )})}
                        {/* <label>
                            <select name="r6_공종">
                                {['-','시스템 동바리', '시스템 비계', '강관 동바리', '강관 비계'].map((a,i)=>{
                                    return(<option value={a}>{a}</option>)
                                })}
                            </select>
                        </label> */}
                    </li>
                </ul>
                <ul>
                    <li>기존 물량도면 유무</li>
                    <li>
                        {['있음', '없음'].map((a,i)=>{
                            return(
                                <label className='radio-wrap'>
                                    <input
                                    type="radio"
                                    name="r6_기존물량도면유무"
                                    value={a}
                                    required
                                    onChange={(e)=>{inputData(setDataObj, 'r6_기존물량도면유무', e.target.value)}}
                                    /> <span>{a}</span>
                                </label>
                        )})}
                        {/* <label>
                            <select name="r6_기존물량도면유무">
                                {['-','있음', '없음'].map((a,i)=>{
                                    return(<option value={a}>{a}</option>)
                                })}
                            </select>
                        </label> */}
                    </li>
                </ul>
                <ul>
                    <li>납기일</li>
                    <li>
                        <label>
                            <input
                                type="date"
                                name="r6_납기일"
                                required
                            />
                        </label>
                    </li>
                </ul>
                <ul>
                    <li>특이사항</li>
                    <li>
                        <label>
                            <input
                                type="text"
                                name="r6_특이사항"
                                // placeholder="공란을 채워주세요"
                                // required
                            />
                        </label>
                    </li>
                </ul>
            </div>
        </>
    )
}