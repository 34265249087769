import { useEffect, useState } from 'react';
import './../../App.css';

export default function Upload(){

    return(
        <>
            <div className='upload'>
                <div className='upload__con'>
                    <div>
                        의뢰서 전송이 완료되었습니다.<br/>
                        이제 파일 첨부를 부탁드리겠습니다.
                    </div>
                    <ul>
                        <li>
                            <span>1. 첨부할 자료를 준비해주시길 바라겠습니다.</span><br/>
                            (1) 공통 : 원도면<br/>
                            (2) 기타 : 기타 자료<br/>
                        </li>
                        <li>
                            <span>2. 이메일로 자료를 전송해주시길 바랍니다.</span><br/>
                            - 이메일 : jhsystem@jhsystem.by-works.com

                        </li>
                        <li>
                            <span>3. 이후에 의뢰서 검토 후 연락드리겠습니다. </span><br/>
                            : 보내주신 자료 검토한 뒤에 연락드리겠습니다. 감사합니다.
                        </li>
                        <li>
                            <img src='/images/logo.png' />
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}