import './App.css';
import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Intro, Footer, Header,
        Upload,
        License
        } from './routes';


function App() {
  return (
    
    <div className="App">
        <Header />
        <Routes>
            <Route path="/" element={<Intro />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/license" element={<License />} />
        </Routes>
        <Footer />
    </div>
  );
}

export default App;
