
import { useEffect, useState } from 'react';
import './../../App.css';
import inputData from '../../modules/inputData/inputData';
import Preview01 from '../Preview01';

export default function Request_1_a(props){
    // Change Object
    let [dataObj, setDataObj] = useState({});

    // Choose subjects
    const [checkItems, setCheckItems] = useState([]);
    const selectChecked = (checked, value) => {
        if (checked) {
          setCheckItems(item => [...item, value]); 
        } else {
          setCheckItems(checkItems.filter((el) => el !== value));
        }
    };

    // // Check only one
    // const checkOnlyOne = (name, checkThis) => {
    //     const checkboxes = document.getElementsByName(name)
    //     console.log(checkboxes)
    //     for (let i = 0; i < checkboxes.length; i++) {
    //         if (checkboxes[i] !== checkThis) {
    //         checkboxes[i].checked = false
    //         }
    //     }
    // }


    return(
        <>
            <div className='form-header'>
                <ul>
                    <li style={{backgroundColor:'#f9e0d0'}}>도면설계 / 견적용 물량도 - 동바리</li>
                </ul>
                <ul>
                    <li>구분</li>
                    <li>내용</li>
                </ul>
                <ul>
                    <li>공종</li>
                    <li>
                        {['시스템 동바리', '강관 동바리(파이프 동바리)'].map((a,i)=>{
                            return(
                                <label className='radio-wrap'>
                                    <input
                                    type="radio"
                                    name="r1a_공종"
                                    value={a}
                                    required
                                    /> <span>{a}</span>
                                </label>
                        )})}
                        {/* <label>
                            <select name="r1a_공종">
                                {['-','시스템 동바리', '강관 동바리(파이프 동바리)'].map((a,i)=>{
                                    return(<option value={a}>{a}</option>)
                                })} 
                            </select>
                        </label> */}
                    </li>
                </ul>
                <ul>
                    <li>의뢰 종류</li>
                    <li>
                        {['도면 설계', '견적용 물량도'].map((a,i)=>{
                            return(
                                <label className='radio-wrap'>
                                    <input
                                    type="radio"
                                    name="r1a_의뢰종류"
                                    value={a}
                                    required
                                    /> <span>{a}</span>
                                </label>
                        )})}
                        {/* <label>
                            <select name="r1a_의뢰종류">
                                {['-','도면 설계', '견적용 물량도'].map((a,i)=>{
                                    return(<option value={a}>{a}</option>)
                                })}
                            </select>
                        </label> */}
                    </li>
                </ul>
                <ul>
                    <li>납기일</li>
                    <li>
                        <label>
                            <input
                                type="date"
                                name="r1a_납기일"
                                required
                            />
                        </label>
                    </li>
                </ul>
                {/* <ul>
                    <li>의뢰서 자동 작성</li>
                    <li>
                        <label>
                            <input
                            type="checkbox" name="r1a_자동작성" value="체크됨" style={{width:'auto'}}
                            onChange={(e) => selectChecked(e.target.checked, 'r1a_자동작성')}
                            /> 기본 내용으로 자동 작성후 의뢰<br/>(내용 보기를 눌러서 확인 후 해당 내용이 아니면 직접 작성하셔야 합니다.)
                        </label>
                        <br/><Preview01 />
                    </li>
                </ul> */}
            
                {checkItems.includes('r1a_자동작성')
                ? ''
                : <div className='form-header'>
                    <ul>
                        <li>설계기준높이</li>
                        <li>
                            {['슬라브 상단 4.20m 이하', '슬라브 상단 4.20m 초과', '(직접 작성)'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1a_설계기준높이"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1a_설계기준높이', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {dataObj.r1a_설계기준높이 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1a_설계기준높이d"
                                    placeholder="직접 작성"
                                    style={{display: dataObj.r1a_설계기준높이 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                            : ''}
                            {/* <label>
                                <select
                                    name="r1a_설계기준높이"
                                    onChange={(e)=>{inputData(setDataObj, 'r1a_설계기준높이', e.target.value)}}
                                >
                                    {['-', '슬라브 상단 4.20m 이하', '슬라브 상단 4.20m 초과', '(직접 작성)'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                                {dataObj.r1a_설계기준높이 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1a_설계기준높이d"
                                    placeholder="직접작성"
                                    style={{display: dataObj.r1a_설계기준높이 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                                : ''}
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>보 하부 멍에재 방향</li>
                        <li>
                            {['장방향', '단방향'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1a_보하부멍에재방향"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1a_보하부멍에재방향', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {/* <label>
                                <select
                                    name="r1a_보하부멍에재방향"
                                >
                                    {['-','장방향', '단방향'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>눈썹보 설치 기준</li>
                        <li>
                            {['300mm 이상 설계', '설계 안함', '(직접 작성)'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1a_눈썹보설치기준"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1a_눈썹보설치기준', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {dataObj.r1a_눈썹보설치기준 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1a_눈썹보설치기준d"
                                    placeholder="직접 작성"
                                    style={{display: dataObj.r1a_눈썹보설치기준 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                            : ''}
                            {/* <label>
                                <select
                                    name="r1a_눈썹보설치기준"
                                    onChange={(e)=>{inputData(setDataObj, 'r1a_눈썹보설치기준', e.target.value)}}
                                >
                                    {['-','300mm 이상 설계', '설계 안함', '(직접 작성)'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                                {dataObj.r1a_눈썹보설치기준 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1a_눈썹보설치기준d"
                                    placeholder="(직접 작성)"
                                    style={{display: dataObj.r1a_눈썹보설치기준 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                                : ''}
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>단열재</li>
                        <li>
                            {['선시공', '후시공'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1a_단열재"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1a_단열재', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {/* <label>
                                <select name="r1a_단열재">
                                    {['-','선시공', '후시공'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>분할타설</li>
                        <li>
                            {['일괄타설 (분할타설 X)', '분할타설 1차 1000mm', '(직접 작성)'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1a_분할타설"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1a_분할타설', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {dataObj.r1a_분할타설 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1a_분할타설d"
                                    placeholder="직접 작성"
                                    style={{display: dataObj.r1a_분할타설 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                            : ''}
                            {/* <label>
                                <select
                                    name="r1a_분할타설"
                                    onChange={(e)=>{inputData(setDataObj, 'r1a_분할타설', e.target.value)}}
                                >
                                    {['-','일괄타설 (분할타설 X)', '분할타설 1차 1000mm', '(직접 작성)'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                                {dataObj.r1a_분할타설 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1a_분할타설d"
                                    placeholder="(직접 작성)"
                                    style={{display: dataObj.r1a_분할타설 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                                : ''}
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>기전실 패드</li>
                        <li>
                            {['선시공', '후시공'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1a_기전실패드"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1a_기전실패드', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {/* <label>
                                <select name="r1a_기전실패드">
                                    {['-','선시공', '후시공'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>방수턱</li>
                        <li>
                            {['선시공', '후시공'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1a_방수턱"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1a_방수턱', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {/* <label>
                                <select name="r1a_방수턱">
                                    {['-','선시공', '후시공'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>데크 구간 발판용 날개</li>
                        <li>
                            {['설계', '설계 안함'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1a_데크구간발판용날개"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1a_데크구간발판용날개', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {/* <label>
                                <select name="r1a_데크구간발판용날개">
                                    {['-','설계', '설계 안함'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>RC 구간 동바리 발판도</li>
                        <li>
                            {['설계', '설계 안함'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1a_RC구간동바리발판도"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1a_RC구간동바리발판도', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {/* <label>
                                <select name="r1a_RC구간동바리발판도">
                                    {['-','설계', '설계 안함'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>물량산출기준<br/>(클릭 시 내용확인 가능)</li>
                        <li>
                            {['기본 적용', '(직접 작성)'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1a_물량산출기준"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1a_물량산출기준', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {dataObj.r1a_물량산출기준 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1a_물량산출기준d"
                                    placeholder="직접 작성"
                                    style={{display: dataObj.r1a_물량산출기준 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                            : ''}
                            {/* <label>
                                <select
                                    name="r1a_물량산출기준"
                                    onChange={(e)=>{inputData(setDataObj, 'r1a_물량산출기준', e.target.value)}}
                                >
                                    {['-','기본 적용', '(직접 작성)'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                                {dataObj.r1a_물량산출기준 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1a_물량산출기준d"
                                    placeholder="(직접 작성)"
                                    style={{display: dataObj.r1a_물량산출기준 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                                : ''}
                            </label> */}
                            <div style={{display: dataObj.r1a_물량산출기준 == '기본 적용' ? 'block' : 'none'}}>
                                [RC]<br/>
                                면적 : 평면상 벽체 내측면 면적<br/>
                                높이 : 슬라브 상단 높이<br/>
                                체적(㎥)=면적*높이*0.9<br/>
                                [DECK]<br/>
                                면적 : 보 실면적<br/>
                                높이 : 보 하단 높이<br/>
                                체적(㎥)=면적*높이<br/>
                                [DECK 보강]<br/>
                                면적 : 보 실면적<br/>
                                높이 : 슬라브 하단 높이<br/>
                                체적(㎥)=면적*높이
                            </div>
                        </li>
                    </ul>
                    <ul>
                        <li>자재 타입</li>
                        <li>
                            {['OK타입 (H18=1829mm) 설계', '(직접 작성)'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1a_자재타입"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1a_자재타입', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {dataObj.r1a_자재타입 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1a_자재타입d"
                                    placeholder="직접 작성"
                                    style={{display: dataObj.r1a_자재타입 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                            : ''}
                            {/* <label>
                                <select
                                    name="r1a_자재타입"
                                    onChange={(e)=>{inputData(setDataObj, 'r1a_자재타입', e.target.value)}}
                                >
                                    {['-','OK타입 (H18=1829mm) 설계', '(직접 작성)'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                                {dataObj.r1a_자재타입 == '(직접 작성)'
                                ? <input
                                    type="text"
                                    name="r1a_자재타입d"
                                    placeholder="(직접 작성)"
                                    style={{display: dataObj.r1a_자재타입 == '(직접 작성)' ? 'block' : 'none'}}
                                    required
                                />
                                : ''}
                            </label> */}
                        </li>
                    </ul>
                    <ul>
                        <li>구조 검토</li>
                        <li>
                            {['비날인 구조계산서 (구조기술사 도장 X)', '날인 구조계산서 (구조기술사 도장 O)'].map((a,i)=>{
                                return(
                                    <label className='radio-wrap'>
                                        <input
                                        type="radio"
                                        name="r1a_구조검토"
                                        value={a}
                                        required
                                        onChange={(e)=>{inputData(setDataObj, 'r1a_구조검토', e.target.value)}}
                                        /> <span>{a}</span>
                                    </label>
                            )})}
                            {/* <label>
                                <select name="r1a_구조검토">
                                    {['-','비날인 구조계산서 (구조기술사 도장 X)', '날인 구조계산서 (구조기술사 도장 O)'].map((a,i)=>{
                                        return(<option value={a}>{a}</option>)
                                    })}
                                </select>
                            </label> */}
                        </li>
                    </ul>
                </div>}
                <ul>
                    <li>특이사항</li>
                    <li>
                        <label>
                            <input
                                type="text"
                                name="r1a_특이사항"
                                // placeholder="공란을 채워주세요"
                            />
                        </label>
                    </li>
                </ul>
            </div>
        </>
    )
}