import { useEffect, useState } from 'react';
import './../App.css';

export default function Law(){

    const [clickHamburger, setClickHamburger] = useState(null);
    const showList = () => {
        setClickHamburger(!clickHamburger);
        console.log(clickHamburger)
    };
    

    return(
        <>
            <span onClick={()=>{showList()}}>[약관보기]</span>
            <div className='law' style={{display: clickHamburger ? 'block' : 'none'}} >
                <div className='law__bg' onClick={()=>{showList()}}></div>
                <div className='law__content'>
                    <div>개인정보처리방침</div>
                    <div>
                        JH시스템(이하 '회사'라고 합니다)은 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하며 귀하의 개인정보보호에 최선을 다하고 있습니다. 회사는 개인정보보호법에 근거하여 다음과 같은 내용으로 개인정보를 수집 및 처리하고자 합니다.<br/><br/>
 
                        다음의 내용을 자세히 읽어보시고 모든 내용을 이해하신 후에 동의 여부를 결정해주시기 바랍니다.<br/><br/>
                        
                        제1조(개인정보 수집 및 이용 목적)<br/>
                        이용자가 제공한 모든 정보는 다음의 목적을 위해 활용하며, 목적 이외의 용도로는 사용되지 않습니다.<br/>
                        - 본인확인 및 의뢰 내용 확인,작업물 송부<br/><br/>
                        
                        제2조(개인정보 수집 및 이용 항목)<br/>
                        회사는 개인정보 수집 목적을 위하여 다음과 같은 정보를 수집합니다.<br/>
                        - 성명, 전화번호 및 이메일<br/><br/>
                        
                        제3조(개인정보 보유 및 이용 기간)<br/>
                        1.	수집한 개인정보는 수집·이용 동의일로부터  개인정보 수집·이용 목적을 달성할 때까지  보관 및 이용합니다.<br/>
                        2.	개인정보 보유기간의 경과, 처리목적의 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br/><br/>
                        
                        제4조(동의 거부 관리)<br/>
                        귀하는 본 안내에 따른 개인정보 수집·이용에 대하여 동의를 거부할 권리가 있습니다. 다만, 귀하가 개인정보 동의를 거부하시는 경우에 업무 내용 확인 및 자료(도면 및 구조검토서) 송부불가의 불이익이 발생할 수 있음을 알려드립니다.<br/><br/>
                        
                        본인은 위의 동의서 내용을 충분히 숙지하였으며,위와 같이 개인정보를 수집·이용하는데 동의합니다.
 
                    </div>
                    <img src='/icons/btn_x.png' onClick={()=>{showList()}} />
                </div>
            </div>
        </>
    )
}